.footer {
  background-color: #010b17;
  padding: 48px;
  color: #fff;
  text-align: center;
  .footer-name {
    font-size: 20px;
  }
  .copyright {
    font-size: 12px;
    margin-top: 8px;
    color: #a9a9a9;
  }
}
