


.nav-container {
  position: sticky;
  top: 0;
  background-color: #010b17;
  color: #fff;
  padding: 24px 0;
  z-index: 10;
  .nav-header {
    margin: auto;
    display: flex;

    .nav-logo {
      width: 180px;
      font-size: 20px;
      font-weight: 700;
      padding-left: 24px;
    }

    .nav-anchor {
      flex: 1;
      :global {
        .ant-anchor {
          .ant-anchor-link {
            margin-right: 48px;
          }
          .ant-anchor-link-title {
            color: #fff;
          }
        }
      }
    }

    .nav-list {
      color: #fff!important;
      flex: 1;
      display: flex;
      list-style: none;
    }

    .nav-actions {
      width: 90px;
    }
  }
}

@media screen and (max-width: 800px) {
  .nav-header {
    justify-content: space-between;
  }
  .nav-anchor {
    display: none;
  }
}
