
:root:root {
  --view-width: 1280px;
  --primary: #20c496;
}

body {
  margin: 0;
  font-family: "Helvetica Neue","PingFang SC", Arial,Helvetica,sans-serif;
}

html, body {
  // 禁用浏览器橡皮筋效果
  overscroll-behavior: none;
}

p, form, ol, ul, li, dl, dt, dd, h1, h2, h3 {
  margin: 0;
  padding: 0;
}
ul, ol {
  list-style: none;
}
